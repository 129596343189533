<template>
  <div class='advertisers-hero'>
    <div class="container">
      <h1>{{$t("advert")}}</h1>
      <h2>{{$t("advertContent")}}</h2>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AdvertisersHero'
  }
</script>